.Blog .container {
  padding-top: 12em;
  padding-bottom: 6em;
}

.Blog h2 {
  text-align: center;
  font-size: 36px;
  font-weight: 600;
}

.Blog .wrapper {
  padding: 0 5em;
  display: flex;
  height: 100%;
}

.Blog .wrapper .left {
  width: 70%;
}

.Blog .wrapper .right {
  width: 30%;
  padding-top: 8em;
  text-align: right;
}

.Blog .post {
}

.Blog .post h2 {
  font-size: 25px;
  text-align: left;
}

.Blog .post .date {
  margin-top: 1em;
  text-decoration: underline;
  text-decoration-color: var(--light-green);
  text-underline-position: under;
}

.Blog .post .image {
  margin-top: 3em;
  width: 100%;
  height: auto;
}

.Blog .post img {
  width: 100%;
}

.Blog .text {
  margin-top: 3em;
  line-height: 1.7;
  text-align: justify;
  white-space: pre-wrap;
}

@media (max-width: 1350px) {
  .Blog .left {
    padding-right: 1em;
  }

  .Blog .right {
    padding-left: 1em;
  }
}

@media (max-width: 1110px) {
  .Blog .right {
    display: none;
  }

  .Blog .left {
    padding-right: 0;
    width: 100% !important;
  }

  .Blog .wrapper {
    justify-content: center;
  }
}

@media (max-width: 850px) {
  .Blog .wrapper {
    padding: 0 2em;
  }
}
