.About {
  margin: 3em auto;
  width: 70%;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.section-title {
  text-align: center;
  color: #000;
  font-size: 36px;
}

.About .section-title {
  margin-bottom: 1em;
}

.About .wrapper img {
  height: 28em;
  display: block;
  margin: 0 auto;
  margin-top: 3em;
  border-bottom: 16px solid var(--light-green);
}

@media (max-width: 725px) {
  .About .wrapper img {
    height: 23em;
  }
}

@media (max-width: 590px) {
  .About .wrapper img {
    height: 18em;
  }
}

@media (max-width: 530px) {
  .About .wrapper img {
    height: 14em;
  }
}

.About .text {
  text-align: center;
  line-height: 1.7;
  font-size: 20px;
}

.About .section {
  margin-top: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.About .section .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.About .section .container img {
  max-width: 45em;
}

.container {
  position: relative;
}

.About .section .bottom-right {
  position: absolute;
  bottom: -14em;
  right: -8em;
  font-size: 18px;
  width: 25em;
  background: var(--light-green);
  height: 18em;
  margin-top: 10em;
  border-bottom: 16px solid var(--dark-green);
  padding: 2em;
  display: flex;
  align-items: center;
  transition: 0.3s;
}

.About .section .bottom-right:hover {
  background: var(--dark-green);
  border-color: var(--light-green);
}

.About .section.top {
  margin-bottom: 18em;
}

.About .section .bottom-right p {
  color: #fff;
  line-height: 1.8;
  text-align: center;
}

@media (min-width: 961px) {
  .About {
    /* margin-bottom: 18em; */
  }

  .About .section .bottom-right:hover {
    margin-bottom: 3em;
  }
}

@media (max-width: 960px) {
  .About {
    width: 90%;
  }

  .About .section .bottom-right {
    position: relative;
    margin: 0;
    bottom: 0;
    right: 0;
  }

  .About .section img {
    width: 28.18em;
  }

  .About .section.top {
    margin-bottom: 3em;
  }
}

@media (max-width: 545px) {
  .About .section img {
    width: 22.4em;
  }

  .About .section .bottom-right {
    width: 20em;
  }
}

@media (max-width: 450px) {
  .About .section img {
    width: 19.13em;
  }

  .About .section .bottom-right {
    width: 17em;
  }

  .About .section p {
    font-size: 17px;
  }

  .About .section .bottom-right {
    padding: 1.3em;
  }
}
