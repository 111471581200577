.AdminMembri h3 {
  text-align: center;
  font-size: 25px;
  margin-top: 2em;
}

.AdminMembri .member-link {
  text-align: center;
  color: white;
  display: block;
  width: 30em;
  background: var(--dark-green);
  margin-top: 3em;
  /* padding-left: 2em; */
  padding-top: 0.4em;
  padding-bottom: 0.4em;
  font-size: 20px;
  font-weight: bold;
}

.AdminMembri .member-link:hover {
  color: var(--light-green);
}

.AdminMembri .form-container {
  padding-top: 5em;
  display: flex;
  justify-content: center;
}

.AdminMembri form {
  display: block;
}

.AdminMembri form input,
.AdminMembri form select,
.AdminMembri form textarea {
  display: block;
  padding: 0.2em;
  margin-top: 1.3em;
  margin-bottom: 1.3em;
  width: 500px;
}

.AdminMembri form button {
  padding: 0.5em;
  cursor: pointer;
}
