.Members {
  padding-top: 12em;
}

.Members .section-title {
  margin-bottom: 2em;
  font-weight: 600;
}

.Members .section {
  margin-top: 5em;
  margin-bottom: 5em;
  text-align: center;
}

.Members .section.first {
  margin-top: 0 !important;
}

.Members .images {
  margin: 0 auto;
  margin-top: 3em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  width: 70%;
  padding: 10px;
  align-items: end;
}

@media (max-width: 992px) {
  .Members .images {
    grid-template-columns: auto;
  }

  .Members .section-title {
    font-size: 23px;
  }
}
@media (max-width: 600px) {
  .Members .images {
    width: 90%;
  }
}

.Members .image {
  margin: 0 1em;
  transform: scale(0.75);
}

.Members .image img {
  /* height: 25em; */
  width: 100%;
  display: block;
  cursor: pointer;
}

.Members .caption {
  background: var(--light-green);
  color: #fff;
  font-size: 20px;
  padding: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
}

.Members .image:hover .caption {
  background: var(--dark-green);
}

@media (max-width: 930px) {
  .Members .images {
    flex-direction: column;
    align-items: center;
  }
  .Members .image {
    margin: 1em 0;
  }
}
