.Banner .media {
  padding: 0;
  display: block;

  height: 100%;
  width: 100%;
  position: relative;
  z-index: -1;
  overflow: hidden;
}

@media (max-width: 900px) {
  .Banner .media {
    width: auto;
    left: 50%;
    top: 50%;
    height: 100vh;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    position: relative;
  }
}

.Banner .container {
  position: relative;
  overflow: hidden;
}

#tsparticles {
  position: absolute !important;
  z-index: 0;
  width: 100%;
  height: 100%;
}
.Banner .intro {
  text-align: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  box-sizing: border-box;
  width: 100%;
  text-shadow: -0.5px -0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000;
}

.Banner .intro h3 {
  text-align: center;
  width: 100%;
  display: inline;
  font-size: 30px;
}

.Banner .arrow {
  position: absolute;
  bottom: 3%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.Banner .arrow .icon {
  transition: 0.3s;
  color: #fff;
  cursor: pointer;
}

.Banner .arrow .icon:hover {
  /* margin-top: -2em; */
  color: var(--light-green);
}

.intro h3 {
  letter-spacing: 4px;
  font-weight: 700;
  text-align: center;
  font-size: 28px;
  max-width: 800px;
}

.intro p {
  text-align: center;
  margin-top: 2em;
  font-size: 25px;

  font-style: italic;
  font-weight: 500;
}

.intro a {
  display: block;
  text-align: center;
  margin-top: 3em;
}

.intro a button {
  border: none;
  outline: none;
  height: 35px;
  width: 150px;
  padding: 7px;
  background: var(--dark-green);
  color: white;
  border: 2px solid var(--dark-green);
  transition: 0.2s;
  cursor: pointer;
}

.intro a button:hover {
  border: 2px solid white;
}

.Banner .tag {
  float: left;
  position: absolute;
  right: 18%;
  top: 0px;
  z-index: 1000;
  background: var(--dark-green);
  padding: 5px;
  color: #ffffff;
  font-weight: bold;
  width: 350px;
  height: 500px;
  border-bottom: 16px solid var(--light-green);
  padding: 4em 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.Banner .tag:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background: var(--light-green);
  border-color: var(--dark-green);
}

.Banner .tag h3 {
  letter-spacing: 2px;
  font-weight: 400;
  text-align: center;
}

.Banner .tag p {
  text-align: center;
  font-weight: 300;
  line-height: 2;
  font-style: italic;
  letter-spacing: 1px;
}

@media (max-width: 1230px) {
  .Banner .tag {
    right: 16%;
  }
}

@media (max-width: 1070px) {
  .Banner .tag {
    height: 420px;
  }
}

@media (max-width: 1120px) {
  .Banner .tag {
    right: 12%;
  }
}

@media (max-width: 960px) {
  .Banner .tag {
    height: 500px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20em;
  }
}

@media (max-width: 835px) {
  .Banner .tag {
    margin-top: 20em;
  }
}

@media (max-width: 756px) {
  .Banner .tag {
    margin-top: 16em;
  }
}

@media (max-width: 630px) {
  .Banner .tag {
    margin-top: 13em;
  }
}

@media (max-width: 550px) {
  .Banner h3 {
    font-size: 23px;
  }
}

@media (max-width: 550px) {
  .Banner .tag {
    margin-top: 12em;
  }
}

@media (max-width: 520px) {
  .Banner .tag {
    margin-top: 10em;
  }
}
