html {
  scroll-behavior: smooth;
}

* {
  font-family: Open Sans;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  /* padding-bottom: 20em; */
}

:root {
  --dark-green: #124559;
  --light-green: #66a396;
}

a {
  text-decoration: none;
  color: #000;
  transition: 0.2s;
}

.styles_scroll-to-top__2A70v {
  margin-bottom: 1.6em !important;
  margin-right: 1.6em !important;
  padding-top: 0.4em;
  padding-bottom: 0.3em;
  background: var(--dark-green) !important;
  border-bottom: 3.5px solid var(--light-green) !important;
  transition: 0.2s !important;
}

.styles_scroll-to-top__2A70v:hover {
  background: var(--light-green) !important;
  border-color: var(--dark-green) !important;
}
