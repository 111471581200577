.Gallery {
  /* background: var(--light-green); */
  /* border-bottom: 16px solid var(--dark-green); */
  /* padding-bottom: 4em; */
  padding: 4em;
  padding-top: 0 !important;
}

.Gallery img {
  height: 25em;
}

.Gallery h2 {
  text-align: center;
  padding-top: 2em;
  margin-bottom: 2em;
  font-size: 36px;
}

.flickity-container {
  --background: #000;
  --size: 10px;
  background-image: linear-gradient(to right, var(--background) var(--size), transparent var(--size)),
    linear-gradient(to bottom, var(--background) var(--size), transparent var(--size)),
    linear-gradient(to right, var(--background) var(--size), transparent var(--size)),
    linear-gradient(to bottom, var(--background) var(--size), transparent var(--size)),
    linear-gradient(to bottom, transparent var(--size), var(--background) var(--size));
  background-size: calc(var(--size) * 2) var(--size), calc(var(--size) * 2) var(--size),
    calc(var(--size) * 2) var(--size), calc(var(--size) * 2) var(--size), 100% calc(100% - var(--size) * 3);
  background-repeat: repeat-x;
  background-position: 0 var(--size), top left, 0 calc(100% - var(--size)), bottom left, 0 var(--size);
  padding: calc(var(--size) * 3) calc(var(--size) * 2.5);
  box-sizing: border-box;
}

.flickity-container img {
  border-left: 10px solid black;
  border-right: 10px solid black;
}

.flickity-button {
  background: var(--light-green) !important;
}

.flickity-button::selection {
  outline: none !important;
  border: none !important;
}

.flickity-page-dots {
  bottom: -60px;
}

.dot {
  background: var(--light-green) !important;
}

.flickity-button-icon {
  color: #fff;
}

.carousel-wrapper {
  padding-top: 2em;
  width: 100%;
  margin: 0 auto;
}

.carousel-wrapper h2 {
  margin: 0;
}

.carousel-container {
  /* height: 10em; */
  min-height: 1px;
  width: 90%;
  overflow: auto;
  padding: 2em;
  padding-top: 1em;
  margin: 0 auto;
}

.carousel-container img {
  /* max-height: 40em; */
  /* max-height: 70%;
  max-width: 70%; */
  margin: 0 auto;
}

.carousel-image-container:focus {
  outline: none;
}

.slick-dots li.slick-active button:before {
  color: var(--dark-green) !important;
}

.slick-dots li button:before {
  color: var(--dark-green) !important;
}

.slick-prev:before,
.slick-next:before {
  color: var(--dark-green) !important;
}

/* @media (max-width: 960px) {
  .Gallery {
  }
} */

@media (max-width: 1136px) {
  .carousel-container img {
    max-height: 22em;
  }
}

@media (max-width: 780px) {
  .carousel-container img {
    max-height: 18em;
  }
}

@media (max-width: 605px) {
  .Gallery {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }

  .carousel-container {
    padding-bottom: 2em;
  }
  .carousel-container img {
    max-height: 16em;
  }
}

@media (max-width: 300px) {
}

div[title='Select'] {
  display: none !important;
}
