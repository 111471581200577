footer {
  margin-top: 5em;
  background: var(--dark-green);
  padding: 2em;
  padding-top: 3em;
  padding-bottom: 0.3em;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top: 16px solid var(--light-green);
  clear: left;
}

footer .title {
  align-self: flex-start;
  width: 70%;
  margin: 0 auto;
}

.footer-title {
  color: white;
  border-bottom: 2px solid var(--light-green);
  display: inline;
  font-weight: 400;
  font-size: 25px;
}

footer .links {
  margin-top: 3em;
  width: 70%;
  display: flex;
  justify-content: space-between;
}

footer .links a,
footer .links span {
  display: block;
  color: white;
  font-weight: 400;
  margin-top: 1em;
  margin-bottom: 1em;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;
}

footer .links a:hover,
footer .links span:hover {
  color: var(--light-green);
}

footer .right {
  text-align: right;
}

footer .social-icon {
  margin-left: 1em;
  fill: white !important;
  display: block;
  margin: 0 auto;
}

footer .social-icon:hover.social-icon path {
  fill: var(--light-green);
  transition: 0.2s;
}

.copyright {
  padding-top: 3em;
  font-size: 13px;
  color: white;
  font-weight: 400;
  text-align: left;
  align-self: flex-start;
  width: 70%;
  margin: 0 auto;
}

.copyright .line {
  border-top: 1px solid var(--light-green);
  margin-bottom: 15px;
  width: 300px;
}

.copyright p {
  margin-bottom: 15px;
}

.copyright a {
  text-decoration: none;
  color: white;
}

.copyright a:hover {
  color: var(--accent-green);
}

footer .separator {
  display: flex;
}

.icon-marginer {
  display: none;
}

@media (max-width: 875px) {
  footer .title,
  footer .links,
  .copyright {
    width: 80%;
  }

  footer .links {
    flex-direction: column;
    align-items: flex-start;
  }

  footer .right {
    text-align: left;
  }

  footer .separator {
    width: 150px;
    border-bottom: 1px solid var(--accent-green);
  }

  .icon-marginer {
    display: block;
  }
}

@media (max-width: 540px) {
  footer .title,
  footer .links,
  .copyright {
    width: 90%;
  }
}

@media (max-width: 376px) {
  footer .title,
  footer .links,
  .copyright {
    width: 100%;
  }
}
