.News .spacer {
  padding-top: 7em;
}

.News {
  margin: 0 auto;
  width: 90%;
}

.News .container {
  display: flex;
  margin: 3em auto;
  justify-content: space-between;
}

.News .left-container {

}

.News .image {
  padding: 0.7em;
  padding-bottom: 0em;
}

.News .left,
.News .right {
  width: 45%;
  display: flex;
  color: white;
}

@media (min-width: 1440px) {
  .News .left,
  .News .right {
    min-height: 43em;
  }
}

@media (max-width: 1160px) {
  .News .left,
  .News .right {
    height: 45em;
  }
}

.News .left-container,
.News .right-container {
  justify-content: space-around;
}

.News .text-container {
  padding: 2em;
  padding-top: 0em;
}

.News .new-title {
  text-align: center;
  font-size: 23px;
}

.News .left {
  background: var(--light-green);
}

.News .left {
  justify-content: center;
  border-bottom: 16px solid var(--dark-green);
}

.News .left .image {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.News .left .image img {
  margin: 0 auto;
  width: 100%;

  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: 0.3s;
  border-radius: 0.3em;
  cursor: pointer;
}

.News .left .image img:hover {
  filter: none;
  -webkit-filter: grayscale(0);
}

.News .left-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.News .text {
  line-height: 1.6;
  font-style: italic;
  text-align: justify;
}

.News .new-title {
  font-weight: 700;
  margin: 0.6em 0;
}

.News .read-more {
  margin-top: 1.4em;
  border: none;
  outline: none;
  height: 35px;
  width: 150px;
  padding: 7px;
  background: var(--dark-green);
  color: white;
  border: 2px solid var(--dark-green);
  transition: 0.2s;
  cursor: pointer;
}

.News .read-more:hover {
  border: 2px solid white;
}

@media (max-width: 875px) {
  .News .container {
    flex-direction: column;
  }

  .News .left,
  .News .right {
    width: 100%;
    height: 52em;
  }

  .News .right {
    margin-top: 2em;
  }
}

@media (max-width: 680px) {
  .News .left,
  .News .right {
    height: 45em;
  }
}
