.MemberInfo {
  padding-top: 12em;
}

.MemberInfo .section-title {
  text-align: left;
  margin-bottom: 1em;
}

.section-title {
  font-weight: 600;
}

@media (max-width: 992px) {
  .MemberInfo .section-title {
    font-size: 23px;
  }
}

.MemberInfo .container {
  margin: 0 auto;
  margin-top: 4em;

  width: 80%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.MemberInfo .image {
  width: 50%;
  margin: 0 auto;
}

.MemberInfo .image img {
  height: 25em;
  margin-top: 1em;
}

@media (min-width: 1020px) {
  .SRLImage {
    height: 75% !important;
  }
}

.MemberInfo .text {
  width: 100%;
  text-align: justify;
  line-height: 1.7;
  white-space: pre-wrap;
  padding: 2em;
  padding-top: 0;
}

@media (max-width: 600px) {
  .MemberInfo .text {
    padding-left: 0;
    padding-right: 0;
  }
}
.MemberInfo .gallery {
  width: 80%;
  margin: 0 auto;
  margin-top: 4em;
}

.MemberInfo .gallery {
  /* text-align: center; */
}

.MemberInfo .thumbnail {
  height: 10em;
  margin-bottom: 0.2em;
  margin-left: 0.2em;
  margin-right: 0.2em;
}

@media (max-width: 1250px) {
  .MemberInfo .gallery {
    width: 90%;
  }
  .MemberInfo .thumbnail {
    width: 100%;
    height: auto;
  }
}

.figure_10ki57k .image_1swebtw-o_O-imageLoaded_zgbg08 {
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}

@media (min-width: 1430px) {
  .figure_10ki57k .image_1swebtw-o_O-imageLoaded_zgbg08 {
    height: 75vh !important;
  }
}

@media (max-width: 760px) {
  .figure_10ki57k .image_1swebtw-o_O-imageLoaded_zgbg08 {
    width: auto;
    height: auto !important;
  }
}

@media (max-width: 1260px) {
  .MemberInfo .container {
    width: 90%;
  }
}

@media (max-width: 1135px) {
  .MemberInfo .container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .MemberInfo .image {
    width: 100%;
    text-align: center;
    margin-bottom: 3em;
  }

  .MemberInfo .text {
    width: 95%;
  }
}
