.AdminMembru {
  padding-bottom: 4em;
}

.AdminMembru h3 {
  text-align: center;
  margin-top: 2em;
  font-size: 25px;
}

.AdminMembru .portrait {
  padding-left: 5em;
  padding-top: 3em;
}

.AdminMembru .portrait img {
  height: 25em;
}

.AdminMembru .descriere p {
  margin-left: 5em;
  padding-top: 2em;
}

.honor-title {
  margin-top: 2em;
  padding-left: 5em;
}

.honor-title span {
  font-weight: bold;
}

.AdminMembru h4 {
  padding-top: 3em;
  padding-left: 5em;
  margin-block-start: 0;
  margin-block-end: 0;
}

.AdminMembru .photos {
  padding-left: 5em;
  margin-top: 5em;
}

.AdminMembru .photos img {
  height: 18em;
  margin-top: 1em;
}

.AdminMembru .photos span {
  margin-left: 5em;
  color: red;
  cursor: pointer;
}

.AdminMembru p {
  white-space: pre-wrap;
}
